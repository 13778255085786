import React from "react";
import "./base.css";
import Container from "./container";
import Navigation from "./navigation";

class Template extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <Container>
        <Navigation />
        {children}
        <div
          style={{ display: "flex", width: "100%", backgroundColor: "white" }}
        >
          <img
            style={{ height: 100, width: 100, marginLeft: "auto" }}
            src="/cdLogo.png"
          />
        </div>
      </Container>
    );
  }
}

export default Template;
